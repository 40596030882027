<template>
    <tab-listado origin="store" />
</template>

<script>
    import TabListado from '../clientes/components/tabListado.vue';

    export default {
        components: {
            TabListado
        }
    }
</script>