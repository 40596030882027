var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.error.length > 0 ? _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Ocurrio un error al obtener los clientes " + _vm._s(_vm.error) + " ")])]) : _c('b-card', {
    attrs: {
      "title": "Clientes"
    }
  }, [_vm.origin !== 'store' ? _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_vm.rolUser === 'PS' ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.createClient
    }
  }, [_vm._v(" Crear ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-1"
  }, [_vm._v("Buscar")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "type": "text",
      "placeholder": "Buscar"
    },
    on: {
      "input": _vm.onSearch
    }
  })], 1)])], 1), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "rows": _vm.rows,
      "columns": _vm.columns,
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.loading,
      "sort-options": {
        enabled: false
      },
      "search-options": {
        enabled: true,
        externalQuery: _vm.searchTerm
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.loading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.loading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'estado' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant(props.row.estado)
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText(props.row.estado)) + " ")])], 1) : props.column.field === 'action' ? _c('span', [_vm.origin !== 'store' ? _c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.editClient($event, props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Editar")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.confirmDeleteClient($event, props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Eliminar")])], 1)], 1)], 1) : _vm._e(), _vm.origin === 'store' ? _c('span', [_c('div', [_c('b-button', {
          attrs: {
            "size": "lg",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onSelectBodega(props.row);
            }
          }
        }, [_c('svg', {
          staticClass: "bi bi-cart",
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "16",
            "height": "16",
            "fill": "currentColor",
            "viewBox": "0 0 16 16"
          }
        }, [_c('path', {
          attrs: {
            "d": "M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          }
        })])])], 1)]) : _vm._e()]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Cantidad de clientes ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        })], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "align": "right",
            "value": 1,
            "per-page": _vm.pageLength,
            "last-number": "",
            "total-rows": props.total,
            "first-number": "",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  }, [_c('template', {
    slot: "emptystate"
  }, [_vm._v("No hay clientes para mostrar")])], 2), _c('b-modal', {
    attrs: {
      "title": "Error",
      "ok-only": "",
      "centered": "",
      "ok-title": "Accept",
      "ok-variant": "danger",
      "modal-class": "modal-danger"
    },
    model: {
      value: _vm.showErrorModal,
      callback: function callback($$v) {
        _vm.showErrorModal = $$v;
      },
      expression: "showErrorModal"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1), _c('b-modal', {
    attrs: {
      "title": "Crear Cliente",
      "ok-only": "",
      "centered": "",
      "ok-variant": "success",
      "modal-class": "modal-success",
      "hide-footer": "",
      "size": "md"
    },
    model: {
      value: _vm.showCreateClientModal,
      callback: function callback($$v) {
        _vm.showCreateClientModal = $$v;
      },
      expression: "showCreateClientModal"
    }
  }, [_c('CreateClient', {
    ref: "create-client",
    on: {
      "onOtp": _vm.sendOTP
    }
  })], 1), _c('b-modal', {
    ref: "modalOPT",
    attrs: {
      "title": "Confirmar codigo OTP",
      "ok-only": "",
      "centered": "",
      "ok-variant": "success",
      "modal-class": "modal-success",
      "hide-footer": "",
      "size": "md"
    },
    model: {
      value: _vm.showModalOPT,
      callback: function callback($$v) {
        _vm.showModalOPT = $$v;
      },
      expression: "showModalOPT"
    }
  }, [_c('Otp', {
    attrs: {
      "dataClient": _vm.dataClient
    }
  })], 1), _c('b-modal', {
    ref: "modalOPT",
    attrs: {
      "title": "Seleccione Bodega",
      "ok-only": "",
      "centered": "",
      "ok-variant": "success",
      "modal-class": "modal-success",
      "hide-footer": "",
      "size": "md"
    },
    model: {
      value: _vm.showModalBodegas,
      callback: function callback($$v) {
        _vm.showModalBodegas = $$v;
      },
      expression: "showModalBodegas"
    }
  }, [_c('v-select', {
    attrs: {
      "getOptionLabel": _vm.getOptionLabel,
      "options": _vm.bodegas,
      "placeholder": "Seleccione la bodega",
      "filterable": ""
    },
    on: {
      "input": _vm.redirectStore
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }