var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tab-listado', {
    attrs: {
      "origin": "store"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }