var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', [_c('b-form-input', {
    directives: [{
      name: "limit-number",
      rawName: "v-limit-number:max",
      value: 9999,
      expression: "9999",
      arg: "max"
    }],
    class: [_vm.error.OTP ? 'is-invalid' : ''],
    attrs: {
      "type": "number",
      "placeholder": "Ingrese codigo otp"
    },
    model: {
      value: _vm.data.OTP,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "OTP", $$v);
      },
      expression: "data.OTP"
    }
  }), _vm.error.OTP ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar el codigo otp ")]) : _vm._e()], 1), _c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.saveOpt
    }
  }, [_vm._v(" Validar ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }