import { computed } from '@vue/composition-api';
<template>
    <div>
        <b-form-group>
            <b-form-input 
              type="number" 
              v-model="data.OTP" 
              placeholder="Ingrese codigo otp"
              v-limit-number:max="9999"
              :class="[error.OTP ? 'is-invalid' : '']"
            ></b-form-input>
            <div v-if="error.OTP" class="invalid-feedback">
              Debe ingrezar el codigo otp
            </div>
        </b-form-group>
        <b-form-group class="mb-2">
            <b-button variant="primary" @click="saveOpt"> Validar </b-button>
        </b-form-group>
    </div>
</template>

<script>
    import { 
        BFormGroup,
        BFormInput,
        BButton
    } from "bootstrap-vue";
    import { getAuthToken } from "@/auth/utils";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components:{
        BFormGroup,
        BFormInput,
        BButton
    },
    props: {
        dataClient: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
           data: {
                OTP: null
           },
           error: {
                OTP: false
           }
        };
    },
    directives: {
        "limit-number": {
        bind(el, binding) {
            el.addEventListener("input", (event) => {
            const value = event.target.value;
            const max = binding.value;
            const validValue = parseInt(value) > max ? value.slice(0,4) : value.replace(/\D/g, "");
            event.target.value = validValue;
            });
        },
        },
    },
    methods: {
        validate(){
            this.error.OTP = this.data.OTP === null || this.data.OTP.length !== 4   
            

            if ( 
                !this.error.OTP
            )
                return true
            return false
        },
        saveOpt() {
            const validate = this.validate()
            if ( validate ) {
                const body = {
                    password: this.data.OTP,
                    fchNacimiento: this.dataClient.date,
                    name: this.dataClient.name,
                    email: this.dataClient.email,
                    empresa: 2,
                    lastname: this.dataClient.lastName,
                    fullname: `${this.dataClient.name} ${this.dataClient.lastName}`,
                    telefono: this.dataClient.phone,
                    codigosms: null,
                    uservalido: true,
                    tokenSesion: getAuthToken(),
                    tiporegistro: 'T',
                    tipoIdentificacion: this.dataClient.typeIdentity,
                    numeroIdentificacion: this.dataClient.identification                  
                }
                console.log('Body OTP: ',body)
                this.$http
                    .post(this.$store.state.app.middlewareURL, {
                        path: "/RegistroUsuario/Crea",
                        body:  JSON.stringify(body),
                    })
                    .then((res) => {
                        if (res.data.bOk) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                icon: "CheckIcon",
                                text: "Se enviaron datos",
                                title: "Exito",
                                variant: "success",
                                },
                            });
                        }  else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                icon: "CheckIcon",
                                text: "Error al validar datos",
                                title: "Exito",
                                variant: "danger",
                                },
                            });
                        }
                    })
                    .catch((e) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            icon: "CheckIcon",
                            text: "Error al validar datos",
                            title: "Exito",
                            variant: "danger",
                            },
                        });
                    });
            }
            
        }
    }
}

</script>