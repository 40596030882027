<template>
    <div>
        <b-form-group>
            <b-form-input 
              type="text" 
              v-model="data.name" 
              placeholder="Nombre"
              :class="[error.name ? 'is-invalid' : '']"
            ></b-form-input>
            <div v-if="error.name" class="invalid-feedback">
              Debe ingrezar el nombre del cliente
            </div>
        </b-form-group>
        <b-form-group>
            <b-form-input 
              type="text" 
              v-model="data.lastName" 
              placeholder="Apellido"
              :class="[error.lastName ? 'is-invalid' : '']"
            ></b-form-input>
            <div v-if="error.lastName" class="invalid-feedback">
              Debe ingrezar el apellido del cliente
            </div>
        </b-form-group>
        <b-form-group
            v-slot="{ ariaDescribedby }"
        >
            <b-form-radio-group
                class="btn-block"
                id="btn-radios-2"
                v-model="data.typeIdentity"
                :options="options"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-primary"
                size="lg"
                name="radio-btn-outline"
                buttons
            ></b-form-radio-group>
        </b-form-group>
        <b-form-group>
            <b-form-input 
              type="number" 
              v-model="data.identification" 
              placeholder="Cédula"
              :class="[error.identification ? 'is-invalid' : '']"
            ></b-form-input>
            <div v-if="error.identification" class="invalid-feedback">
              Debe ingrezar el cédula de identidad
            </div>
        </b-form-group>
        <b-form-group>
            <b-form-input 
              type="email" 
              v-model="data.email" 
              placeholder="Correo electrónico"
              :class="[error.email ? 'is-invalid' : '']"
            ></b-form-input>
            <div v-if="error.email" class="invalid-feedback">
              Debe ingrezar un correo electrónico
            </div>
        </b-form-group>
        <b-form-group>
            <b-form-input 
              type="number" 
              v-model="data.phone" 
              placeholder="Número telefornico"
              :class="[error.phone ? 'is-invalid' : '']"
            ></b-form-input>
            <div v-if="error.phone" class="invalid-feedback">
              Debe ingrezar un número de telefono
            </div>
        </b-form-group>
        <b-form-group label-for="vi-fecha-fin">
            <b-input-group class="input-group-merge">
                <input
                  type="date"
                  placeholder="Fecha de nacimiento"
                  v-model="data.date"
                  :class="[error.date ? 'is-invalid' : '','form-control']"
                />
                <div v-if="error.date" class="invalid-feedback">
                  Debe seleccionar la fecha de nacimiento
                </div>
            </b-input-group>
        </b-form-group>
        <b-form-group class="mb-2">
            <b-button variant="primary" @click="saveClient"> Guardar </b-button>
        </b-form-group>
    </div>
</template>

<script>
import { 
        BFormGroup,
        BFormInput,
        BInputGroup,
        BFormRadioGroup,
        BFormDatepicker,
        BButton
    } from "bootstrap-vue";
    import { getAuthToken } from "@/auth/utils";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


    export default {
        components: {
            BFormGroup,
            BFormInput,
            BInputGroup,
            BFormRadioGroup,
            BFormDatepicker,
            BButton
        },
        data() {
            return {
                error: {
                    name: false,
                    lastName: false,
                    identification: false,
                    email: false,
                    phone: false,
                    date: false
                },
                data: {
                    name: '',
                    lastName: '',
                    identification: null,
                    email: '',
                    phone: null,
                    date: '',
                    typeIdentity: 'C'
                },
                options: [
                    { text: 'Cédula', value: 'C' },
                    { text: 'Pasaporte', value: 'P' },
                ]
            }
        },
        methods: {
            validate() {
                this.error.name = this.data.name.length === 0
                this.error.lastName = this.data.lastName.length === 0
                this.error.identification = this.data.identification === null || this.data.identification.length === 0
                this.error.email = this.data.email.length === 0
                this.error.phone = this.data.phone === null || this.data.phone.length === 0
                this.error.date = this.data.date.length === 0

                if(
                    this.error.name ||
                    this.error.lastName ||
                    this.error.identification ||
                    this.error.email ||
                    this.error.phone ||
                    this.error.date
                )
                    return false

                return true
            },
            saveClient() {
                const validate =  this.validate()
                if ( validate ){
                    if ( validate ){
                        const body = {
                            canal: "SEASY",
                            empresa: 2,
                            email: this.data.email,
                            celular: this.data.phone,
                            tokenSesion: getAuthToken(),
                            numeroIdentificacion: this.data.identification,
                            tipoIdentificacion: this.data.typeIdentity
                        }
                        this.$http
                            .post(this.$store.state.app.middlewareURL, {
                                path: "/RegistroUsuario/GeneraTokenRegister",
                                body:  JSON.stringify(body),
                            })
                            .then((res) => {
                                if (res.data.bOk) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                        icon: "CheckIcon",
                                        text: "Se enviaron datos",
                                        title: "Exito",
                                        variant: "success",
                                        },
                                    });
                                } else {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                        icon: "CheckIcon",
                                        text: "Error al enviar datos",
                                        title: "Exito",
                                        variant: "danger",
                                        },
                                    });
                                }
                                this.$emit('onOtp', this.data)
                            })
                            .catch((e) => {
                                this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                        icon: "CheckIcon",
                                        text: "Error al enviar datos",
                                        title: "Exito",
                                        variant: "danger",
                                        },
                                    });
                            });
                    }
                    
                }
            }
        }
    }   
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.list-group-item {
  transition: all 1s;
}
.f1 {
  flex: 1;
}
</style>
