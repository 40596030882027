<template>
  <div>
    <b-alert v-if="error.length > 0" variant="danger" show>
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrio un error al obtener los clientes {{ error }}
      </div>
    </b-alert>

    <b-card v-else title="Clientes">
      <b-row class="mt-2" v-if="origin !== 'store'">
        <b-col cols="12" md="3" class="mb-2">
          <b-button variant="primary" v-if="rolUser === 'PS'" @click="createClient"> Crear </b-button>
        </b-col>
      </b-row>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Buscar</label>
            <b-form-input
              type="text"
              class="d-inline-block"
              @input="onSearch"
              placeholder="Buscar"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :rows="rows"
        :columns="columns"
        :totalRows="totalRecords"
        :isLoading.sync="loading"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        :sort-options="{
          enabled: false,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="emptystate">No hay clientes para mostrar</template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: Estado -->
          <span v-if="props.column.field === 'estado'">
            <b-badge :variant="statusVariant(props.row.estado)">
              {{ statusText(props.row.estado) }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span v-if="origin !== 'store'">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editClient($event, props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Editar</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="confirmDeleteClient($event, props.row)"
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-if="origin === 'store'">
              <div >
                <b-button size="lg" variant="primary" @click="onSelectBodega(props.row)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                </b-button>
              </div>
            </span>
          </span>

          <!-- Column: Otras Columnas -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Cantidad de clientes </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '15', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
            </div>
            <div>
              <b-pagination
                align="right"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                :value="1"
                :per-page="pageLength"
                last-number
                :total-rows="props.total"
                first-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        title="Error"
        ok-only
        v-model="showErrorModal"
        centered
        ok-title="Accept"
        ok-variant="danger"
        modal-class="modal-danger"
      >
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
      <b-modal
          title="Crear Cliente"
          ok-only
          v-model="showCreateClientModal"
          centered
          ok-variant="success"
          modal-class="modal-success"
          hide-footer
          size="md"
      >
        <CreateClient
          ref="create-client"
          @onOtp="sendOTP"
        />
      </b-modal>
      <b-modal
          ref="modalOPT"
          title="Confirmar codigo OTP"
          ok-only
          v-model="showModalOPT"
          centered
          ok-variant="success"
          modal-class="modal-success"
          hide-footer
          size="md"
      >
        <Otp 
          :dataClient="dataClient"
        />
      </b-modal>
      <b-modal
          ref="modalOPT"
          title="Seleccione Bodega"
          ok-only
          v-model="showModalBodegas"
          centered
          ok-variant="success"
          modal-class="modal-success"
          hide-footer
          size="md"
      >
        <v-select
          :getOptionLabel="getOptionLabel"
          :options="bodegas"
          placeholder="Seleccione la bodega"
          filterable
          @input="redirectStore"
        />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BAvatar,
  BSpinner,
  BDropdown,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BDropdownItem,
  BButton,
  BModal
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Otp from "./Otp.vue";
import CreateClient from "./CreateClient.vue";
import { FeatherIcon } from "vue-feather-icons";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BAvatar,
    BSpinner,
    BDropdown,
    BCardText,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    ToastificationContent,
    BButton,
    BModal,
    CreateClient,
    Otp,
    vSelect
},
  directives: {
    Ripple,
  },
  props: {
    origin: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      page: 1,
      rows: [],
      error: "",
      loading: true,
      searchTerm: "",
      pageLength: 10,
      totalRecords: 0,
      seachTimeout: null,
      showErrorModal: false,
      showCreateClientModal: false,
      showModalOPT: false,
      showModalBodegas: false,
      rolUser: '',
      dataClient: {},
      bodegas: [],
      client: {},
      bodega: null,
      columns: [
        {
          label: "Identificación",
          field: "identificacion",
        },
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Apellido",
          field: "apellido",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Estado",
          field: "estado",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.rolUser = user.rol
    this.getBodegas(),
    this.getClientes();
  },
  computed: {
    statusText() {
      const status = {
        A: "Activo",
        I: "Inactivo",
        E: "Eliminado",
      };
      return (s) => status[s];
    },
    statusVariant() {
      const statusColor = {
        E: "light-danger",
        I: "light-warning",
        A: "light-success",
      };
      return (s) => statusColor[s];
    },
  },
  methods: {
    getBodegas() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Warehouse/GetWarehousesMarket",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.status === 200) {
            this.bodegas = res.data;
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          // this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    getOptionLabel(option) {
      return option.name
    },
    onSelectBodega(client) {
      this.client = client
      this.showModalBodegas = true
      //redirectStore($event,props.row)">
    },
    redirectStore(bodega) {
      this.$router.push({
        name: "tienda-cliente",
        params: {
          client: this.client,
          bodega: bodega
        }
      });
    },
    sendOTP(dataClient) {
      this.dataClient =  dataClient
      this.showModalOPT = true
    },
    createClient() {
      this.showCreateClientModal = true
    },
    onChangeFilters() {
      this.getClientes();
    },
    onSearch(query) {
      this.loading = true;
      this.searchTerm = query;
      clearTimeout(this.seachTimeout);
      this.seachTimeout = setTimeout(this.getClientes, 2500);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.getClientes();
    },
    onPerPageChange(params) {
      this.pageLength = params.currentPerPage;
      this.getClientes();
    },
    onSortChange(params) {
      this.sort = {
        type: params.sortType,
        field: this.columns[params.columnIndex].field,
      };
      this.getClientes();
    },
    editClient(event, client) {
      event && event.preventDefault();
      this.$router.push({
        name: "clientes-edit",
        params: { id: client.cliente, cliente: client },
      });
    },
    confirmDeleteClient(event, client) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea eliminar el cliente?.", {
          size: "sm",
          title: "Confirmacion",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.deleteClient(client);
          }
        });
    },
    getClientes() {
      this.loading = true;
      let bodyparams = JSON.stringify({
        filas: this.pageLength,
        filtro: this.searchTerm,
        pagina: this.page,
        empresa: 2,
        tokenSesion: getAuthToken(),
      });
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/list/mini",
          body: bodyparams,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.rows = res.data.lista;
            this.totalRecords = res.data.totalRegistros;
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    deleteClient(client) {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/user/super-easy/delete",
          body: JSON.stringify({
            UserId: client.cliente,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getClientes();
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se elimino correctamente el cliente`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error  al eliminar el cliente (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        });
    },
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
