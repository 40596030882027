var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.name ? 'is-invalid' : ''],
    attrs: {
      "type": "text",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  }), _vm.error.name ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar el nombre del cliente ")]) : _vm._e()], 1), _c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.lastName ? 'is-invalid' : ''],
    attrs: {
      "type": "text",
      "placeholder": "Apellido"
    },
    model: {
      value: _vm.data.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "lastName", $$v);
      },
      expression: "data.lastName"
    }
  }), _vm.error.lastName ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar el apellido del cliente ")]) : _vm._e()], 1), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          staticClass: "btn-block",
          attrs: {
            "id": "btn-radios-2",
            "options": _vm.options,
            "aria-describedby": ariaDescribedby,
            "button-variant": "outline-primary",
            "size": "lg",
            "name": "radio-btn-outline",
            "buttons": ""
          },
          model: {
            value: _vm.data.typeIdentity,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "typeIdentity", $$v);
            },
            expression: "data.typeIdentity"
          }
        })];
      }
    }])
  }), _c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.identification ? 'is-invalid' : ''],
    attrs: {
      "type": "number",
      "placeholder": "Cédula"
    },
    model: {
      value: _vm.data.identification,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "identification", $$v);
      },
      expression: "data.identification"
    }
  }), _vm.error.identification ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar el cédula de identidad ")]) : _vm._e()], 1), _c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.email ? 'is-invalid' : ''],
    attrs: {
      "type": "email",
      "placeholder": "Correo electrónico"
    },
    model: {
      value: _vm.data.email,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "email", $$v);
      },
      expression: "data.email"
    }
  }), _vm.error.email ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar un correo electrónico ")]) : _vm._e()], 1), _c('b-form-group', [_c('b-form-input', {
    class: [_vm.error.phone ? 'is-invalid' : ''],
    attrs: {
      "type": "number",
      "placeholder": "Número telefornico"
    },
    model: {
      value: _vm.data.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "phone", $$v);
      },
      expression: "data.phone"
    }
  }), _vm.error.phone ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingrezar un número de telefono ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-for": "vi-fecha-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.date,
      expression: "data.date"
    }],
    class: [_vm.error.date ? 'is-invalid' : '', 'form-control'],
    attrs: {
      "type": "date",
      "placeholder": "Fecha de nacimiento"
    },
    domProps: {
      "value": _vm.data.date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.data, "date", $event.target.value);
      }
    }
  }), _vm.error.date ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe seleccionar la fecha de nacimiento ")]) : _vm._e()])], 1), _c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.saveClient
    }
  }, [_vm._v(" Guardar ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }